import { useCallback, useState, type FC } from 'react';
import styles from './index.module.scss';
import uploadIcon from '@assets/images/pump/upload.svg';
import loadingIcon from '@assets/images/pump/loading.svg';
import { useDropzone } from 'react-dropzone';
import http from '@libs/http';

interface OwnProps {
  onChange?(value: string): void;
  value?: string;
}

const Pump: FC<OwnProps> = ({ onChange, value }) => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('file', file);
    });

    try {
      setLoading(true);
      const response = await http.post<{ url: string }>('/file/icon', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUrl(response.url);
      if (onChange) onChange(response.url);
    } catch (error) {
      console.error('Upload error:', error);
    }
    setLoading(false);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    await handleUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 2 * 1024 * 1024,
  });

  const finalUrl = value !== undefined ? value : url;
  return (
    <div {...getRootProps({ className: 'dropzone' })} className={styles.upload}>
      {finalUrl ? (
        <img src={finalUrl} className={styles.url} />
      ) : (
        <>
          <img src={uploadIcon} />
          <div>Project Profile *</div>
          <div className={styles.tip}>MaxSize: 2M</div>
        </>
      )}
      <input {...getInputProps()} />
      {loading && (
        <div className={styles.loading}>
          <img src={loadingIcon} />
          <div>Uploading...</div>
        </div>
      )}
    </div>
  );
};

export default Pump;
