import { FC } from 'react';
import styles from './index.module.scss';

const Banner: FC = () => {
  return (
    <div className={styles.banner}>
      <div
        className={`${styles.slide} ${styles.usdc}`}
        onClick={() => {
          window.open('https://x.com/Turbos_finance/status/1856223911178014931');
        }}
      ></div>
    </div>
  );
};

export default Banner;
